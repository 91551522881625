import React, { useEffect } from 'react'
import axios from "axios";
import Cookies from "universal-cookie";

const Logout = () => {

    useEffect(() => {
        logoutUser();
    }, []);
    
    const logoutUser = async () => {
        const cookies = new Cookies();
        const token = cookies.get('auth')
        console.log(token)
        const response = await axios.post("https://digitalfrencrm.herokuapp.com/api/v1/admin/logout", {},{ headers: { "Authorization": `Bearer ${token}` } })
        console.log(response);
        window.location='/signin'
      }

    return (
        <div>
            
        </div>
    )
}

export default Logout
