import React, { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
// import { makeStyles } from '@material-ui/core/styles';
import Container from "@material-ui/core/Container";
import axios from "axios";
import Cookies from "universal-cookie";


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="">
        UPKO
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [show, setshow] = useState(false)
  //   const classes = useStyles();

  const login = async (e) => {
    e.preventDefault();
    setshow(false);
    const response = await axios.post(
      "https://digitalfrencrm.herokuapp.com/api/v1/admin/login",
      {
        email,
        password,
      }
    );
    if (response.status === 200) {
      const cookies = new Cookies();
      cookies.set("auth", response.data.data.accessToken, { path: "/" });
      window.location = "/";
    }
    else {
      setshow(true)
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div
        style={{
          marginTop: "8px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {
          show===true?<h3
          style={{color:"red"}}
        >Invalid Credentials</h3> : <div></div>
        }
        <Avatar
          style={{
            margin: "1px",
            backgroundColor: "white",
          }}
        >
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
              <form
                  onSubmit={login}
          style={{
            width: "100%", // Fix IE 11 issue.
            marginTop: "1px",
          }}
          noValidate
        >
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Username"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            style={{ margin: "3px,opx,2px" }}
            
          >
            Sign In
          </Button>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
