import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import { format } from "date-fns";
import Members from '../pages/members';
import {
  Box,
  Chip,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { Scrollbar } from "./scrollbar";

export const TeamsTable = (props) => {
  const { orders } = props;
  return (
    <div>
      <Scrollbar>
        <Table sx={{ minWidth: 1000 }}>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Team Name</TableCell>
              <TableCell>Created At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((order) => {
              // const statusVariant = statusVariants.find(
              //   (variant) => variant.value === "order.id"
              // );

              return (
                <TableRow key={order._id}>
                  <TableCell>
                    <Link
                      color="inherit"
                      component={RouterLink}
                      to="members/Team 1"
                      underline="none"
                      variant="subtitle2"
                    >
                      {`#${order._id}`}
                    </Link>
                  </TableCell>

                  <TableCell>{`${order.name} `}</TableCell>
                  <TableCell>
                    <Box>
                      <Typography color="inherit" variant="inherit">
                        {format(new Date(order.createdAt), "dd MMM yyyy")}
                      </Typography>
                      <Typography color="textSecondary" variant="inherit">
                        {format(new Date(order.createdAt), "HH:mm")}
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Scrollbar>
    </div>
  );
};
