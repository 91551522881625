import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { format } from 'date-fns';
import {
  Box,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import { Scrollbar } from './scrollbar';



export const OrdersTable = (props) => {
  const { orders } = props;

  return (
    <div>
      <Scrollbar>
        <Table sx={{ minWidth: 1000 }}>
          <TableHead>
            <TableRow>
              <TableCell>
                Name
              </TableCell>
              <TableCell>
                Phone No.
              </TableCell>
              <TableCell>
                Team
              </TableCell>
              <TableCell>
                Email ID
              </TableCell>
              <TableCell>
                Joined At
              </TableCell>
            </TableRow>
          </TableHead>
         
          <TableBody>
            
            {orders.map((order) => {
              // const statusVariant = statusVariants.find(
              //   (variant) => variant.value === "order.id"
              // );

              return (
                <TableRow key={order.id}>
                  
                  
                  <TableCell>
                    {`${order.fullName} `}
                  </TableCell>

                  <TableCell>
                    {/* <Link
                      color="inherit"
                      component={RouterLink}
                      to="#"
                      underline="none"
                      variant="subtitle2"
                    >
                      {`#${order.id}`}
                    </Link> */}
                    {order.phoneNo}
                  </TableCell>
                  
                  <TableCell>
                  {`${order.team} `}
                    {/* <Chip
                      label={statusVariant.label}
                      variant="outlined"
                    /> */}
                  </TableCell>
                  <TableCell>
                  {`${order.email} `}
                    {/* <Box>
                      <Typography
                        color="inherit"
                        variant="inherit"
                      >
                        {format(new Date(order.createdAt), 'dd MMM yyyy')}
                      </Typography>
                      <Typography
                        color="textSecondary"
                        variant="inherit"
                      >
                        {format(new Date(order.createdAt), 'HH:mm')}
                      </Typography>
                    </Box> */}
                  </TableCell>
                  <TableCell>
                    <Box>
                      <Typography
                        color="inherit"
                        variant="inherit"
                      >
                        {format(new Date(order.createdAt), 'dd MMM yyyy')}
                      </Typography>
                      <Typography
                        color="textSecondary"
                        variant="inherit"
                      >
                        {format(new Date(order.createdAt), 'HH:mm')}
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
                
              );
            })}
          </TableBody>
        </Table>
      </Scrollbar>
    </div>
  );
};

OrdersTable.propTypes = {
  orders: PropTypes.array.isRequired
};
