import React from "react";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  TablePagination,
  Typography,
} from "@material-ui/core";
// import { OrdersFilter } from '../components/orders/orders-filter';
import { TeamsTable } from "../components/teams-table";
// import Button from '@material-ui/core/Button';
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import axios from "axios";

export const Teams = () => {
  useEffect(() => {
    getData();
  }, []);

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [teams, setTeams] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [teamName, setTeamName] = useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const getData = async () => {
    const response = await axios.get(
      "https://digitalfrencrm.herokuapp.com/api/v1/admin/getAllcat"
    );
    console.log(response.data.data);
    setTeams(response.data.data);
    console.log(teams);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const addTeam = async () => {
    const response = await axios.post(
      "https://digitalfrencrm.herokuapp.com/api/v1/admin/addCat",
      {
        name: teamName,
      }
    );

    getData();
    handleClose();
  };

  return (
    <>
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Add New Team</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Team Name"
              type="email"
              fullWidth
              onChange={(e) => setTeamName(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={addTeam} color="primary">
              Add
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <Helmet>
        <title>Teams</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          pb: 3,
          pt: 8,
        }}
      >
        <Container maxWidth="lg">
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              mb: 3,
            }}
          >
            <Typography color="textPrimary" variant="h4">
              Teams
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Button
              color="primary"
              size="large"
              variant="contained"
              onClick={handleClickOpen}
            >
              Add Team
            </Button>
          </Box>
          <Card variant="outlined">
            {/* <OrdersFilter
              mode={mode}
              onModeChange={handleModeChange}
              onQueryChange={handleQueryChange}
              query={query}
            /> */}
            <Divider />
            <TeamsTable orders={teams} />
            <Divider />
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={teams.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Container>
      </Box>
    </>
  );
};
