import React from "react";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  TablePagination,
  Typography,
} from "@material-ui/core";
import { OrdersTable } from "../components/orders-table";
import axios from "axios";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import MenuItem from "@material-ui/core/MenuItem";
import { CSVLink } from "react-csv";

export const Orders = () => {
  useEffect(() => {
    getData();
  }, []);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);
  const [orders, setOrders] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [teamName, setTeamName] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [team, setTeam] = useState("");
  const [selectedTeam, setSelectedTeam] = useState("All teams")

  const getData = async () => {
    const response = await axios.get(
      "https://digitalfrencrm.herokuapp.com/api/v1/admin/getUsers"
    );
    // console.log(response.data.data);
    setOrders(response.data.data);
    // console.log(orders);
    
    const response2 = await axios.get(
      "https://digitalfrencrm.herokuapp.com/api/v1/admin/getAllcat"
    );
    // console.log(response2.data.data);
    setTeamName(response2.data.data);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const addTeam = async () => {
    const response = await axios.post(
      "https://digitalfrencrm.herokuapp.com/api/v1/Customer/register",
      {
        email, phoneNo, password, firstName,lastName,team
      }
    );

    getData();
    handleClose();
    // console.log(teamName);
  };

  const handleChange = (event) => {
    setTeam(event.target.value);
  };

  const sortedData = async (val) => {
    // console.log(selectedTeam)
    const response = await axios.get(`https://digitalfrencrm.herokuapp.com/api/v1/admin/members/${val}`
     );
    //  console.log(response.data.data);
     setOrders(response.data.data.result);
    //  console.log(orders);
  }

  const handleChange2 =  (event) => {
    setSelectedTeam(event.target.value);
    // console.log(event.target.value);
    event.target.value === "All teams" ? getData() : sortedData(event.target.value);
  };

  const headers = [
    { label: "User ID", key: "id" },
    { label: "Name", key: "fullName" },
    { label: "Email", key: "email" },
    { label: "Team", key: "team" },
    { label: "Phone Number", key: "phoneNo"}
  ];

  const csvReport = {
    data: orders,
    headers: headers,
    filename: 'Users_data.csv'
  };

  return (
    <>
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Add New User</DialogTitle>
          <DialogContent>
            <TextField
              id="outlined-basic"
              margin="dense"
              label="First Name"
              variant="outlined"
              onChange={(e) => setFirstName(e.target.value)}
            />
            <TextField
              style={{
                marginLeft: "10px",
              }}
              id="outlined-basic"
              margin="dense"
              label="Last Name"
              variant="outlined"
              onChange={(e) => setLastName(e.target.value)}
            />
            <TextField
              id="outlined-basic"
              margin="dense"
              label="E-mail"
              variant="outlined"
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              style={{
                marginLeft: "10px",
              }}
              id="outlined-basic"
              type="password"
              margin="dense"
              label="password"
              variant="outlined"
              onChange={(e) => setPassword(e.target.value)}
            />
            <TextField
              id="outlined-basic"
              type="number"
              margin="dense"
              label="Phone Number"
              variant="outlined"
              onChange={(e) => setPhoneNo(e.target.value)}
            />
            <TextField
              style={{
                marginLeft: "10px",
                marginTop: "7px",
              }}
              id="standard-select-currency"
              select
              value={team}
              onChange={handleChange}
              helperText="Select Team"
            >
              
              {teamName.map((option) => (
            <MenuItem key={option._id} value={option.name}>
              {option.name}
            </MenuItem>
          ))}
            </TextField>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={addTeam} color="primary">
              Add
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <Helmet>
        <title>Users</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          pb: 3,
          pt: 8,
        }}
      >
        <Container maxWidth="lg">
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              mb: 3,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection:"column"
            }}
            >
            <Typography color="textPrimary" variant="h4">
              Users
            </Typography>
            
            <Button
              color="primary"
              size="large"
              variant="contained"
            >
              <CSVLink
                style={{
                  textDecoration: 'none',
                  color:"white"
              }}
                {...csvReport}>Export this data to CSV</CSVLink>
              </Button>
              </div>
            <Box sx={{ flexGrow: 1 }} />
            
            
            <Button
              color="primary"
              size="large"
              variant="contained"
              onClick={handleClickOpen}
            >
              Add User
            </Button>
            <TextField
              style={{
                marginLeft: "10px",
                marginTop: "7px",
              }}
              id="standard-select"
              select
              value={selectedTeam}
              onChange={handleChange2}
              helperText="Select Team"
            >
               <MenuItem key="All teams" value="All teams" >
              All teams
            </MenuItem>
              {teamName.map((option) => (
                
            <MenuItem key={option._id} value={option.name}>
              {option.name}
            </MenuItem>
              ))}
             
            </TextField>
          </Box>
          <Card variant="outlined">
            <Divider />
            <OrdersTable orders={orders} />
            <Divider />
            <TablePagination
              rowsPerPageOptions={[ 25,30,50]}
              component="div"
              count={orders.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Container>
      </Box>
    </>
  );
};
