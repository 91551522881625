import React, { useEffect,useState } from "react";
import { Outlet } from 'react-router-dom';
import { styled } from '@material-ui/core/styles';
import { Navbar } from './navbar';
import { Sidebar } from './sidebar';
// import { Footer } from './footer';
import axios from "axios";
import Cookies from 'universal-cookie';
import CircularProgress from '@material-ui/core/CircularProgress';




const DashboardLayoutRoot = styled('div')(
  ({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  })
);

const DashboardLayoutContentWrapper = styled('div')(
  () => ({
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingLeft: 73,
    paddingTop: '64px'
  })
);

const DashboardLayoutContent = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  flexDirection: 'column',
  overflow: 'auto'
});

// export const Layout = () => (
//   <DashboardLayoutRoot>
//     <Navbar />
//     <Sidebar />
//     <DashboardLayoutContentWrapper>
//       <DashboardLayoutContent>
//         <Outlet />
//         {/* <Footer /> */}
//       </DashboardLayoutContent>
//     </DashboardLayoutContentWrapper>
//   </DashboardLayoutRoot>
// );

export const Layout = () => {
  useEffect(() => {
    authenticate();
  }, []);
  const [loggedIn, setloggedIn] = useState(false);

  
  
  const authenticate = async () => {
    const cookies = new Cookies();
    const token = cookies.get('auth');
    // 5.2.0
    const response = await axios.get("https://digitalfrencrm.herokuapp.com/api/v1/admin/getProfile", { headers: { "Authorization": `Bearer ${token}` } })
    
    if (response.status !== 200) {
      window.location="/signin"
    }
    else if(response.status===200){
      setloggedIn(true);
    }
  }
  if (loggedIn === true) {
    
  
    return (
    
      <div>
        <DashboardLayoutRoot>
          <Navbar />
          <Sidebar />
          <DashboardLayoutContentWrapper>
            <DashboardLayoutContent>
              <Outlet />
              {/* <Footer /> */}
            </DashboardLayoutContent>
          </DashboardLayoutContentWrapper>
        </DashboardLayoutRoot>
      </div>
    )
  }
  else {
    return (
      <div
        style={{
          height:"100%",
          display: 'flex',
          alignItems: "center",
          justifyContent:"center"
      }}
      >
        <CircularProgress
          variant="indeterminate"
          disableShrink
        style={{strokeLinecap: 'round',}}
          size="50px" />
      </div>
    )
    
  }
}

