import React from "react";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  TablePagination,
  Typography,
} from "@material-ui/core";
import { OrdersTable } from "../components/orders-table";
import axios from "axios";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";

const Members = () => {
  useEffect(() => {
    getData();
  }, []);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [orders, setOrders] = useState([]);
  const [teamNames, setTeamNames] = useState([]);
  const [team, setTeam] = useState("All Teams");
  const getData = async () => {
    const response = await axios.get(
      `https://digitalfrencrm.herokuapp.com/api/v1/admin/members/${team}`
    );
    console.log(response.data.data);
    setOrders(response.data.data.result);
    console.log(orders);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChange = (event) => {
    setTeam(event.target.value);
  };

  return (
    <>
      <Helmet>
        <title>Users</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          pb: 3,
          pt: 8,
        }}
      >
        <Container maxWidth="lg">
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              mb: 3,
            }}
          >
            <Typography color="textPrimary" variant="h4">
              Users
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <TextField
              style={{
                marginLeft: "10px",
                marginTop: "7px",
              }}
              id="standard-select-currency"
              select
              value={team}
              onChange={handleChange}
              helperText="Select Team"
            >
              
              {teamNames.map((option) => (
            <MenuItem key={option._id} value={option.name}>
              {option.name}
            </MenuItem>
          ))}
            </TextField>
          </Box>
          <Card variant="outlined">
            <Divider />
            <OrdersTable orders={orders} />
            <Divider />
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={orders.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default Members;
